
.profile-panel {
  background: #ffffff;
  box-shadow: 0px 2px 34px rgba(46, 48, 92, 0.11);
  padding: 20px;
  .notfound {
        font-weight: bold;
        text-align: center;
      }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 767px) {
      padding: 0px !important;
    }
    .name {
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 25px;

      color: #000000;
    }
    .edit-btn {
      cursor: pointer;
      background: #fa8c28;
      border-radius: 4px;
      padding: 10px 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      color: #ffffff;
    }
  }
  .avatar {
    margin-top: 30px;
    margin-bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      border: #ccc solid thin;
      border-radius: 200px;
      outline-offset: 8px;
    }
  }
  .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    .key {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      color: rgb(41, 35, 35);
      @media all and (max-width: 380px) {
        font-size: 14px;
      }
    }
    .value {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */
      text-align: right;
      color: #000;

      @media all and (max-width: 380px) {
        font-size: 14px;
      }
    }
  }
  .splitter {
    width: 100%;
    height: 0;
    border-bottom: 1px solid #eeeeee;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 0px;
    .name {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;

      line-height: 25px;

      color: #000000;
    }
    .delete-btn {
      cursor: pointer;
      background: #ff6666;
      border-radius: 4px;
      padding: 10px 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-decoration: none;
      color: #ffffff;
    }
  }
}
